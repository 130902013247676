import React, { useState, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table, Row, Col } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import parse from 'html-react-parser';
import { actionCreators } from '../store/Nsi';
const VersionsHistory = (props) => {
    const [] = useState(() => {
        if (props.nsi.versions_history.length === 0) {
            props.getNs('versions_history');
        }
        return true;
    }, props.nsi.versions_history.length > 0);
    return <Row><Col md={6}><h5 className="text-primary"><strong><Translate value="versions.tit" /></strong></h5>
        <Table striped bordered hover>
            <thead>
                <tr><th><Translate value="versions.versionNo" /></th><th><Translate value="versions.dtVersion" /></th><th><Translate value="versions.comment" /></th></tr>
            </thead>
            <tbody>
                {props.nsi.versions_history.map((d, i) => <tr key={i}>
                    <td>{d.versionNo}</td>
                    <td>{d.versionDt}</td>
                    <td>{parse(d.comment)}</td>
                </tr>)}
            </tbody>
        </Table>
    </Col>
        <Col md={6}>
            <h5 className="text-primary"><strong><Translate value="info.docs" /></strong></h5>
            <div><strong>№ 08-01/15182-И от 20.12.2024 :</strong>&nbsp;&nbsp;
                <a href="downloads/Письмо из МЗРК_каз.docx" download style={{ fontWeight: "bold", textDecoration: "underline" }}>Каз</a>&nbsp;&nbsp;
                <a href="downloads/Письмо из МЗРК_рус.docx" download style={{ fontWeight: "bold", textDecoration: "underline" }}>Рус</a>
            </div>
            <div><strong>Приложение 1:</strong>&nbsp;&nbsp;
                <a href="downloads/Приложение 1 рус.docx" download style={{ fontWeight: "bold", textDecoration: "underline" }}>Рус</a>
            </div>
            <div><strong>Приложение 2:</strong>&nbsp;&nbsp;
                <a href="downloads/Приложение 2 рус.docx" download style={{ fontWeight: "bold", textDecoration: "underline" }}>Рус</a>
            </div>
            <div><strong>Приложение 3:</strong>&nbsp;&nbsp;
                <a href="downloads/Приложение 3 рус.docx" download style={{ fontWeight: "bold", textDecoration: "underline" }}>Рус</a>
            </div>
        </Col>
    </Row>
}
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)//,
)(VersionsHistory);