import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Translate } from 'react-redux-i18n';

import { actionCreators } from '../../store/FinPaidData';
import DogovorModal from '../Modals/DogovorModal';
import FormsModal from '../Modals/FormsModal';
import CheckBoxQuarter from '../common/CheckBoxQuarter';
import CheckBoxIsPrivateOrg from '../common/CheckBoxIsPrivateOrg';
import CheckBox50pers from '../common/CheckBox50pers';
import './PaidsTable.css';
//import { Translate } from 'react-redux-i18n';
class PaidsTable extends Component {
    constructor(props) {
        super(props);
        this.state = { searchNameOrg: undefined, showDogovorModal: false, showFormsModal: false, selectObj: undefined };
        this.changeOrgNameFilter = this.changeOrgNameFilter.bind(this);
        //this.setPaid = this.setPaid.bind(this);
        this.setPaid4 = this.setPaid4.bind(this);
        this.setPaid3 = this.setPaid3.bind(this);
        this.handleHideDogovorModal = this.handleHideDogovorModal.bind(this);
        this.btShowDogovors = this.btShowDogovors.bind(this);
        this.btShowDogovorsObj = this.btShowDogovorsObj.bind(this);
        this.showObjPaids = this.showObjPaids.bind(this);
        this.setIsPrivateOrg = this.setIsPrivateOrg.bind(this);
        this.setIs50pers = this.setIs50pers.bind(this);
        this.btShowFormsObj = this.btShowFormsObj.bind(this);
        this.handleHideFormsModal = this.handleHideFormsModal.bind(this);
        this.handleCommitBtFormsModal = this.handleCommitBtFormsModal.bind(this)
    }
    /*
    componentWillMount() {
        // This method runs when the component is first added to the page
    }
    */
    componentWillReceiveProps(nextProps) {
        // This method runs when incoming props (e.g., route params) change
        if (this.props.paids.year != nextProps.paids.year || this.props.selectedObj.id != nextProps.selectedObj.id)
            this.setState({ searchNameOrg: undefined });
    }
    changeOrgNameFilter(e) {
        var searchNameOrg = e.target.value;
        this.setState({ searchNameOrg });
    }
    setPaid3(d) {
        if (d.typeObj/*this.props.paids.typeObj*/ === 4) {
            this.props.setPaidOrg(this.props.paids.year, d.kod, d.quarter, d.value);
        } else /*if (this.props.paids.typeObj === 2)*/ {
            this.props.setPaidTer(this.props.paids.year, d.kod, d.quarter, d.value);
        }
    }
    setPaid4(d) {
        this.props.setPaidTer(this.props.paids.year, d.kod, d.quarter, d.value);
    }
    handleHideDogovorModal() {
        this.setState({ showDogovorModal: false, selectObj: undefined });
    }
    handleHideFormsModal(forms) {
        this.setState({ showFormsModal: false, selectObj: undefined });
    } 
    handleCommitBtFormsModal(forms) {
        if (forms != null) {
            this.props.setPaidFormsOrg(this.props.paids.year, this.state.selectObj.kod, forms);
        }
        this.setState({ showFormsModal: false, selectObj: undefined });
    }
    btShowDogovors() {
        this.setState({
            showDogovorModal: true,
            selectObj: { kod: this.props.selectedObj.id, nameRu: this.props.selectedObj.label, typeObj: this.props.paids.typeObj }
        });
    }
    btShowDogovorsObj(obj) {
        this.setState({
            showDogovorModal: true,
            selectObj: obj//{ kod: this.props.selectedObj.id, nameRu: this.props.selectedObj.label, typeObj: this.props.paids.typeObj }
        });
    }
    btShowFormsObj(obj) {
        this.setState({
            showFormsModal: true,
            selectObj: obj//{ kod: this.props.selectedObj.id, nameRu: this.props.selectedObj.label, typeObj: this.props.paids.typeObj }
        });
    }
    showObjPaids() {
        return '';
        /*
        var obj = this.props.paids.obj;
        return obj != null ?
                <div className="col-md-6">
                    <table className='table'>
                        <tbody>
                        <tr>
                            <td><CheckBoxQuarter kod={obj.kod} quarter={'1'} checked={this.props.paids.quarter1} onChange={this.setPaid4} />1 квартал</td>
                            <td><CheckBoxQuarter kod={obj.kod} quarter={'2'} checked={this.props.paids.quarter2} onChange={this.setPaid4} />2 квартал</td>
                            <td><CheckBoxQuarter kod={obj.kod} quarter={'3'} checked={this.props.paids.quarter3} onChange={this.setPaid4} />3 квартал</td>
                            <td><CheckBoxQuarter kod={obj.kod} quarter={'4'} checked={this.props.paids.quarter4} onChange={this.setPaid4} />4 квартал</td>
                        </tr>
                        </tbody>
                    </table>
                </div> : '';
      */
    }
    setIsPrivateOrg(d) {
        //if (d.typeObj === 4) {
        this.props.setIsPrivateOrg(d.kod, d.isPrivateOrg);
        //}
    }
    setIs50pers(d) {
        this.props.setIs50persOrg(d.kod, this.props.paids.year, d.is50pers);
    }
    render() {
        var objsItems = [];
        if (this.props.selectedObj !== undefined) {
            if (this.props.isLoading === false && this.props.paids !== undefined && this.props.paids.objsItems !== undefined) {
                objsItems = this.props.paids.objsItems;
                if (this.state.searchNameOrg !== undefined) {
                    let nameOrgS = this.state.searchNameOrg.toUpperCase();
                    objsItems = this.props.paids.objsItems.filter(itm => itm.obj.nameRu.toUpperCase().includes(nameOrgS) || itm.obj.kod.startsWith(nameOrgS) || (itm.obj.bin != null && itm.obj.bin.startsWith(nameOrgS)));
                }
            }
            return (<Fragment>
                <div style={{ marginLeft: "8px" }}><strong>{this.props.paids !== undefined && this.props.paids.year !== undefined ? this.props.paids.year + " год" : ''}</strong></div>
                <div className="row" style={{ marginLeft: "8px" }}>
                    <div className="col-md-6">
                        <strong>{this.props.selectedParentObj.label} {this.props.selectedObj.label}</strong>
                        <button type="button" className="btn btn-primary" onClick={this.btShowDogovors}>Договоры</button>
                    </div>
                    {this.showObjPaids()}
                </div>
                {this.props.isLoading === true ? <div className="alert alert-success" role="alert">Загрузка...</div> :<div className="row" style={{ marginLeft: "8px" }}>
                                {/*<div class="col-sm">
                                    <div class="row">*/}
                    {/*<div className="col-sm-4"><strong>Фильтр Наименование/Код/Бин</strong></div>*/}
                    <div className="col-sm-12"><div style={{ display: "inline-block" }}><strong>Фильтр Наименование/Код/Бин:</strong></div>
                        <div style={{ display: "inline-block" }}><input type="text" className="form-control" onChange={this.changeOrgNameFilter} value={this.state.searchNameOrg} style={{ width: "500px" }} /></div></div>
                                {/*    </div>
                                </div>
                                    <div class="col-sm">
                                    <div class="row">
                                        <div class="col-sm">Бин</div>
                                        <div class="col-sm"><input type="text" className="form-control" onChange={this.changeOrgNameFilter} value={this.state.searchNameOrg} /></div>
                                    </div>
                                </div>*/}
                        </div>}
                {this.props.paids !== undefined && this.props.paids.objsItems !== undefined ? <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th>Организация</th>
                            <th><Translate value="fin50persOrg" /></th>
                            <th>1 квартал</th>
                            <th>2 квартал</th>
                            <th>3 квартал</th>
                            <th>4 квартал</th>
                        </tr>
                    </thead>
                    <tbody>
                        {objsItems.map(item => <tr key={item.obj.kod}><td>
                            <div className="obj_name"><strong>{item.obj.kod}</strong> {item.obj.nameRu}</div>
                            {/* {item.obj.typeObj === 4 ? <div className="obj_name" style={{ paddingLeft: "6px", fontWeight: "bold" }}><input type="checkbox" checked={item.obj.isPrivateOrg === 1} onChange={() => this.setIsPrivateOrg(item.obj)} /><span style={{ marginLeft: "3px", color: "darkgreen" }}><Translate value="finPrivateOrg" /></span></div> : ""}*/}
                            {item.obj.typeObj === 4 ? <><CheckBoxIsPrivateOrg checked={item.obj.isPrivateOrg === 1} kod={item.obj.kod} onChange={this.setIsPrivateOrg} />
                                <div className="obj_name">&nbsp;&nbsp;&nbsp;<strong className="text-success">Бин:</strong>{item.obj.bin}&nbsp;&nbsp;&nbsp;<button type="button" className="btn btn-success" onClick={() => { this.btShowFormsObj(item.obj); }}>Формы</button>&nbsp;<span className="text-success">Формы:&nbsp;{item.forms != null && item.forms.length > 0 ? item.forms : "Все"}</span></div>&nbsp;&nbsp;&nbsp;
                            </> : ""}
                            <div className="obj_name"><button type="button" className="btn btn-primary" onClick={() => { this.btShowDogovorsObj(item.obj); }}>Договоры</button></div>
                        </td>
                            <td>
                                {item.obj.typeObj === 4 ? <CheckBox50pers checked={item.is50pers === 1} kod={item.obj.kod} onChange={this.setIs50pers} /> : ""}
                            </td>
                            <td><CheckBoxQuarter kod={item.obj.kod} typeObj={item.obj.typeObj} quarter={'1'} checked={item.quarter1} onChange={this.setPaid3} /></td>
                            <td><CheckBoxQuarter kod={item.obj.kod} typeObj={item.obj.typeObj} quarter={'2'} checked={item.quarter2} onChange={this.setPaid3} /></td>
                            <td><CheckBoxQuarter kod={item.obj.kod} typeObj={item.obj.typeObj} quarter={'3'} checked={item.quarter3} onChange={this.setPaid3} /></td>
                            <td><CheckBoxQuarter kod={item.obj.kod} typeObj={item.obj.typeObj} quarter={'4'} checked={item.quarter4} onChange={this.setPaid3} /></td>
                        </tr>)}
                    </tbody>
                </table>
                    : ''}
                <DogovorModal showModal={this.state.showDogovorModal}
                    obj={this.state.selectObj} handleHide={this.handleHideDogovorModal} />
                {this.state.selectObj != null ? <FormsModal showModal={this.state.showFormsModal} obj={this.state.selectObj} year={this.props.paids.year}
                    objKod={this.state.selectObj.kod} handleHide={this.handleHideFormsModal} commitBt={this.handleCommitBtFormsModal}/> : ""}
            </Fragment>);
        } else {
            return (<div className="text-success"><strong>Выберите область, район</strong></div>);
        }
    }
}
export default connect(
    state => state.finPaidData,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(PaidsTable);

